var exports = {};
exports = {
  A: {
    A: {
      J: 0.0131217,
      D: 0.00621152,
      E: 0.0497493,
      F: 0.0580409,
      A: 0.00829156,
      B: 0.406286,
      FC: 0.009298
    },
    B: "ms",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "FC", "J", "D", "E", "F", "A", "B", "", "", ""],
    E: "IE",
    F: {
      FC: 962323200,
      J: 998870400,
      D: 1161129600,
      E: 1237420800,
      F: 1300060800,
      A: 1346716800,
      B: 1381968000
    }
  },
  B: {
    A: {
      C: 0.004081,
      K: 0.004267,
      L: 0.004268,
      G: 0.004081,
      M: 0.003702,
      N: 0.004081,
      O: 0.016324,
      P: 0,
      Q: 0.004298,
      R: 0.00944,
      S: 0.004043,
      T: 0.004081,
      U: 0.003861,
      V: 0.003861,
      W: 0.004081,
      X: 0.003943,
      Y: 0.004081,
      Z: 0.003943,
      a: 0.003943,
      b: 0.008162,
      c: 0.004118,
      d: 0.003939,
      e: 0.003943,
      i: 0.003943,
      j: 0.003943,
      k: 0.003929,
      l: 0.003901,
      m: 0.011829,
      n: 0.007886,
      o: 0.003943,
      p: 0.008162,
      q: 0.004081,
      r: 0.004081,
      s: 0.008162,
      t: 0.016324,
      u: 0.044891,
      v: 0.118349,
      f: 2.0609,
      w: 2.19966,
      H: 0
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "C", "K", "L", "G", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z", "a", "b", "c", "d", "e", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "f", "w", "H", "", "", ""],
    E: "Edge",
    F: {
      C: 1438128000,
      K: 1447286400,
      L: 1470096000,
      G: 1491868800,
      M: 1508198400,
      N: 1525046400,
      O: 1542067200,
      P: 1579046400,
      Q: 1581033600,
      R: 1586736000,
      S: 1590019200,
      T: 1594857600,
      U: 1598486400,
      V: 1602201600,
      W: 1605830400,
      X: 1611360000,
      Y: 1614816000,
      Z: 1618358400,
      a: 1622073600,
      b: 1626912000,
      c: 1630627200,
      d: 1632441600,
      e: 1634774400,
      i: 1637539200,
      j: 1641427200,
      k: 1643932800,
      l: 1646265600,
      m: 1649635200,
      n: 1651190400,
      o: 1653955200,
      p: 1655942400,
      q: 1659657600,
      r: 1661990400,
      s: 1664755200,
      t: 1666915200,
      u: 1670198400,
      v: 1673481600,
      f: 1675900800,
      w: 1678665600,
      H: 1680825600
    },
    D: {
      C: "ms",
      K: "ms",
      L: "ms",
      G: "ms",
      M: "ms",
      N: "ms",
      O: "ms"
    }
  },
  C: {
    A: {
      "0": 0.013698,
      "1": 0.004161,
      "2": 0.008786,
      "3": 0.004118,
      "4": 0.004317,
      "5": 0.004393,
      "6": 0.004418,
      "7": 0.008834,
      "8": 0.008322,
      "9": 0.008928,
      GC: 0.004118,
      vB: 0.004271,
      I: 0.011703,
      x: 0.004879,
      J: 0.020136,
      D: 0.005725,
      E: 0.004525,
      F: 0.00533,
      A: 0.004283,
      B: 0.008162,
      C: 0.004471,
      K: 0.004486,
      L: 0.00453,
      G: 0.008322,
      M: 0.004417,
      N: 0.004425,
      O: 0.004161,
      y: 0.004443,
      g: 0.004283,
      z: 0.008322,
      AB: 0.004471,
      BB: 0.009284,
      CB: 0.004707,
      DB: 0.009076,
      EB: 0.004081,
      FB: 0.004783,
      GB: 0.003929,
      HB: 0.004783,
      IB: 0.00487,
      JB: 0.005029,
      KB: 0.0047,
      LB: 0.012243,
      MB: 0.004081,
      NB: 0.003867,
      OB: 0.004525,
      PB: 0.004293,
      QB: 0.004081,
      RB: 0.004538,
      SB: 0.008282,
      TB: 0.011601,
      UB: 0.044891,
      VB: 0.011601,
      WB: 0.003929,
      XB: 0.003974,
      YB: 0.008162,
      ZB: 0.011601,
      aB: 0.003939,
      wB: 0.004081,
      bB: 0.003929,
      xB: 0.004356,
      cB: 0.004425,
      dB: 0.008322,
      eB: 0.00415,
      fB: 0.004267,
      gB: 0.003801,
      hB: 0.004267,
      iB: 0.004081,
      jB: 0.00415,
      kB: 0.004293,
      lB: 0.004425,
      mB: 0.008162,
      h: 0.00415,
      nB: 0.00415,
      oB: 0.004318,
      pB: 0.004356,
      qB: 0.003974,
      rB: 0.036729,
      P: 0.004081,
      Q: 0.004081,
      R: 0.004081,
      yB: 0.003861,
      S: 0.004081,
      T: 0.003929,
      U: 0.004268,
      V: 0.003801,
      W: 0.012243,
      X: 0.008162,
      Y: 0.003943,
      Z: 0.003943,
      a: 0.012243,
      b: 0.003801,
      c: 0.007722,
      d: 0.016324,
      e: 0.003773,
      i: 0.007886,
      j: 0.003901,
      k: 0.003901,
      l: 0.004081,
      m: 0.003861,
      n: 0.004081,
      o: 0.110187,
      p: 0.036729,
      q: 0.008162,
      r: 0.008162,
      s: 0.008162,
      t: 0.012243,
      u: 0.024486,
      v: 0.053053,
      f: 1.10187,
      w: 0.926387,
      H: 0.008162,
      zB: 0,
      "0B": 0,
      HC: 0.008786,
      IC: 0.00487
    },
    B: "moz",
    C: ["GC", "vB", "HC", "IC", "I", "x", "J", "D", "E", "F", "A", "B", "C", "K", "L", "G", "M", "N", "O", "y", "g", "z", "0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "AB", "BB", "CB", "DB", "EB", "FB", "GB", "HB", "IB", "JB", "KB", "LB", "MB", "NB", "OB", "PB", "QB", "RB", "SB", "TB", "UB", "VB", "WB", "XB", "YB", "ZB", "aB", "wB", "bB", "xB", "cB", "dB", "eB", "fB", "gB", "hB", "iB", "jB", "kB", "lB", "mB", "h", "nB", "oB", "pB", "qB", "rB", "P", "Q", "R", "yB", "S", "T", "U", "V", "W", "X", "Y", "Z", "a", "b", "c", "d", "e", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "f", "w", "H", "zB", "0B", ""],
    E: "Firefox",
    F: {
      "0": 1368489600,
      "1": 1372118400,
      "2": 1375747200,
      "3": 1379376000,
      "4": 1386633600,
      "5": 1391472000,
      "6": 1395100800,
      "7": 1398729600,
      "8": 1402358400,
      "9": 1405987200,
      GC: 1161648000,
      vB: 1213660800,
      HC: 1246320000,
      IC: 1264032000,
      I: 1300752000,
      x: 1308614400,
      J: 1313452800,
      D: 1317081600,
      E: 1317081600,
      F: 1320710400,
      A: 1324339200,
      B: 1327968000,
      C: 1331596800,
      K: 1335225600,
      L: 1338854400,
      G: 1342483200,
      M: 1346112000,
      N: 1349740800,
      O: 1353628800,
      y: 1357603200,
      g: 1361232000,
      z: 1364860800,
      AB: 1409616000,
      BB: 1413244800,
      CB: 1417392000,
      DB: 1421107200,
      EB: 1424736000,
      FB: 1428278400,
      GB: 1431475200,
      HB: 1435881600,
      IB: 1439251200,
      JB: 1442880000,
      KB: 1446508800,
      LB: 1450137600,
      MB: 1453852800,
      NB: 1457395200,
      OB: 1461628800,
      PB: 1465257600,
      QB: 1470096000,
      RB: 1474329600,
      SB: 1479168000,
      TB: 1485216000,
      UB: 1488844800,
      VB: 1492560000,
      WB: 1497312000,
      XB: 1502150400,
      YB: 1506556800,
      ZB: 1510617600,
      aB: 1516665600,
      wB: 1520985600,
      bB: 1525824000,
      xB: 1529971200,
      cB: 1536105600,
      dB: 1540252800,
      eB: 1544486400,
      fB: 1548720000,
      gB: 1552953600,
      hB: 1558396800,
      iB: 1562630400,
      jB: 1567468800,
      kB: 1571788800,
      lB: 1575331200,
      mB: 1578355200,
      h: 1581379200,
      nB: 1583798400,
      oB: 1586304000,
      pB: 1588636800,
      qB: 1591056000,
      rB: 1593475200,
      P: 1595894400,
      Q: 1598313600,
      R: 1600732800,
      yB: 1603152000,
      S: 1605571200,
      T: 1607990400,
      U: 1611619200,
      V: 1614038400,
      W: 1616457600,
      X: 1618790400,
      Y: 1622505600,
      Z: 1626134400,
      a: 1628553600,
      b: 1630972800,
      c: 1633392000,
      d: 1635811200,
      e: 1638835200,
      i: 1641859200,
      j: 1644364800,
      k: 1646697600,
      l: 1649116800,
      m: 1651536000,
      n: 1653955200,
      o: 1656374400,
      p: 1658793600,
      q: 1661212800,
      r: 1663632000,
      s: 1666051200,
      t: 1668470400,
      u: 1670889600,
      v: 1673913600,
      f: 1676332800,
      w: 1678752000,
      H: 1681171200,
      zB: null,
      "0B": null
    }
  },
  D: {
    A: {
      "0": 0.003901,
      "1": 0.008786,
      "2": 0.003939,
      "3": 0.004461,
      "4": 0.004141,
      "5": 0.004326,
      "6": 0.0047,
      "7": 0.004538,
      "8": 0.008322,
      "9": 0.008596,
      I: 0.004706,
      x: 0.004879,
      J: 0.004879,
      D: 0.005591,
      E: 0.005591,
      F: 0.005591,
      A: 0.004534,
      B: 0.004464,
      C: 0.010424,
      K: 0.0083,
      L: 0.004706,
      G: 0.015087,
      M: 0.004393,
      N: 0.004393,
      O: 0.008652,
      y: 0.008322,
      g: 0.004393,
      z: 0.004317,
      AB: 0.004566,
      BB: 0.004118,
      CB: 0.004081,
      DB: 0.008162,
      EB: 0.004335,
      FB: 0.004464,
      GB: 0.016324,
      HB: 0.003867,
      IB: 0.016324,
      JB: 0.003773,
      KB: 0.003974,
      LB: 0.008162,
      MB: 0.007948,
      NB: 0.003974,
      OB: 0.003867,
      PB: 0.008162,
      QB: 0.020405,
      RB: 0.036729,
      SB: 0.003867,
      TB: 0.004081,
      UB: 0.008162,
      VB: 0.008162,
      WB: 0.003867,
      XB: 0.004081,
      YB: 0.073458,
      ZB: 0.004081,
      aB: 0.015772,
      wB: 0.003773,
      bB: 0.016324,
      xB: 0.008162,
      cB: 0.003773,
      dB: 0.012243,
      eB: 0.003943,
      fB: 0.008162,
      gB: 0.028567,
      hB: 0.008162,
      iB: 0.008162,
      jB: 0.036729,
      kB: 0.016324,
      lB: 0.012243,
      mB: 0.020405,
      h: 0.008162,
      nB: 0.036729,
      oB: 0.048972,
      pB: 0.044891,
      qB: 0.016324,
      rB: 0.024486,
      P: 0.134673,
      Q: 0.036729,
      R: 0.04081,
      S: 0.065296,
      T: 0.04081,
      U: 0.089782,
      V: 0.065296,
      W: 0.097944,
      X: 0.020405,
      Y: 0.036729,
      Z: 0.04081,
      a: 0.077539,
      b: 0.044891,
      c: 0.085701,
      d: 0.044891,
      e: 0.032648,
      i: 0.04081,
      j: 0.053053,
      k: 0.04081,
      l: 0.053053,
      m: 0.053053,
      n: 0.048972,
      o: 0.073458,
      p: 0.355047,
      q: 0.114268,
      r: 0.106106,
      s: 0.077539,
      t: 0.126511,
      u: 0.399938,
      v: 2.73019,
      f: 7.54169,
      w: 10.7208,
      H: 0.020405,
      zB: 0.020405,
      "0B": 0.004081,
      JC: 0
    },
    B: "webkit",
    C: ["", "", "", "", "", "I", "x", "J", "D", "E", "F", "A", "B", "C", "K", "L", "G", "M", "N", "O", "y", "g", "z", "0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "AB", "BB", "CB", "DB", "EB", "FB", "GB", "HB", "IB", "JB", "KB", "LB", "MB", "NB", "OB", "PB", "QB", "RB", "SB", "TB", "UB", "VB", "WB", "XB", "YB", "ZB", "aB", "wB", "bB", "xB", "cB", "dB", "eB", "fB", "gB", "hB", "iB", "jB", "kB", "lB", "mB", "h", "nB", "oB", "pB", "qB", "rB", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z", "a", "b", "c", "d", "e", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "f", "w", "H", "zB", "0B", "JC"],
    E: "Chrome",
    F: {
      "0": 1343692800,
      "1": 1348531200,
      "2": 1352246400,
      "3": 1357862400,
      "4": 1361404800,
      "5": 1364428800,
      "6": 1369094400,
      "7": 1374105600,
      "8": 1376956800,
      "9": 1384214400,
      I: 1264377600,
      x: 1274745600,
      J: 1283385600,
      D: 1287619200,
      E: 1291248000,
      F: 1296777600,
      A: 1299542400,
      B: 1303862400,
      C: 1307404800,
      K: 1312243200,
      L: 1316131200,
      G: 1316131200,
      M: 1319500800,
      N: 1323734400,
      O: 1328659200,
      y: 1332892800,
      g: 1337040000,
      z: 1340668800,
      AB: 1389657600,
      BB: 1392940800,
      CB: 1397001600,
      DB: 1400544000,
      EB: 1405468800,
      FB: 1409011200,
      GB: 1412640000,
      HB: 1416268800,
      IB: 1421798400,
      JB: 1425513600,
      KB: 1429401600,
      LB: 1432080000,
      MB: 1437523200,
      NB: 1441152000,
      OB: 1444780800,
      PB: 1449014400,
      QB: 1453248000,
      RB: 1456963200,
      SB: 1460592000,
      TB: 1464134400,
      UB: 1469059200,
      VB: 1472601600,
      WB: 1476230400,
      XB: 1480550400,
      YB: 1485302400,
      ZB: 1489017600,
      aB: 1492560000,
      wB: 1496707200,
      bB: 1500940800,
      xB: 1504569600,
      cB: 1508198400,
      dB: 1512518400,
      eB: 1516752000,
      fB: 1520294400,
      gB: 1523923200,
      hB: 1527552000,
      iB: 1532390400,
      jB: 1536019200,
      kB: 1539648000,
      lB: 1543968000,
      mB: 1548720000,
      h: 1552348800,
      nB: 1555977600,
      oB: 1559606400,
      pB: 1564444800,
      qB: 1568073600,
      rB: 1571702400,
      P: 1575936000,
      Q: 1580860800,
      R: 1586304000,
      S: 1589846400,
      T: 1594684800,
      U: 1598313600,
      V: 1601942400,
      W: 1605571200,
      X: 1611014400,
      Y: 1614556800,
      Z: 1618272000,
      a: 1621987200,
      b: 1626739200,
      c: 1630368000,
      d: 1632268800,
      e: 1634601600,
      i: 1637020800,
      j: 1641340800,
      k: 1643673600,
      l: 1646092800,
      m: 1648512000,
      n: 1650931200,
      o: 1653350400,
      p: 1655769600,
      q: 1659398400,
      r: 1661817600,
      s: 1664236800,
      t: 1666656000,
      u: 1669680000,
      v: 1673308800,
      f: 1675728000,
      w: 1678147200,
      H: 1680566400,
      zB: null,
      "0B": null,
      JC: null
    }
  },
  E: {
    A: {
      I: 0,
      x: 0.008322,
      J: 0.004656,
      D: 0.004465,
      E: 0.003974,
      F: 0.003929,
      A: 0.004425,
      B: 0.004318,
      C: 0.003801,
      K: 0.020405,
      L: 0.102025,
      G: 0.024486,
      KC: 0,
      "1B": 0.008692,
      LC: 0.008162,
      MC: 0.00456,
      NC: 0.004283,
      OC: 0.028567,
      "2B": 0.007802,
      sB: 0.008162,
      tB: 0.036729,
      "3B": 0.183645,
      PC: 0.289751,
      QC: 0.044891,
      "4B": 0.036729,
      "5B": 0.093863,
      "6B": 0.171402,
      "7B": 0.754985,
      uB: 0.08162,
      "8B": 0.261184,
      "9B": 0.465234,
      AC: 1.69361,
      BC: 0.028567,
      CC: 0,
      RC: 0
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "KC", "1B", "I", "x", "LC", "J", "MC", "D", "NC", "E", "F", "OC", "A", "2B", "B", "sB", "C", "tB", "K", "3B", "L", "PC", "G", "QC", "4B", "5B", "6B", "7B", "uB", "8B", "9B", "AC", "BC", "CC", "RC", ""],
    E: "Safari",
    F: {
      KC: 1205798400,
      "1B": 1226534400,
      I: 1244419200,
      x: 1275868800,
      LC: 1311120000,
      J: 1343174400,
      MC: 1382400000,
      D: 1382400000,
      NC: 1410998400,
      E: 1413417600,
      F: 1443657600,
      OC: 1458518400,
      A: 1474329600,
      "2B": 1490572800,
      B: 1505779200,
      sB: 1522281600,
      C: 1537142400,
      tB: 1553472000,
      K: 1568851200,
      "3B": 1585008000,
      L: 1600214400,
      PC: 1619395200,
      G: 1632096000,
      QC: 1635292800,
      "4B": 1639353600,
      "5B": 1647216000,
      "6B": 1652745600,
      "7B": 1658275200,
      uB: 1662940800,
      "8B": 1666569600,
      "9B": 1670889600,
      AC: 1674432000,
      BC: 1679875200,
      CC: null,
      RC: null
    }
  },
  F: {
    A: {
      "0": 0.006597,
      "1": 0.013434,
      "2": 0.006702,
      "3": 0.006015,
      "4": 0.005595,
      "5": 0.004393,
      "6": 0.008162,
      "7": 0.004879,
      "8": 0.004879,
      "9": 0.003861,
      F: 0.0082,
      B: 0.016581,
      C: 0.004317,
      G: 0.00685,
      M: 0.00685,
      N: 0.00685,
      O: 0.005014,
      y: 0.006015,
      g: 0.004879,
      z: 0.006597,
      AB: 0.005152,
      BB: 0.005014,
      CB: 0.009758,
      DB: 0.004879,
      EB: 0.004081,
      FB: 0.004283,
      GB: 0.004367,
      HB: 0.004534,
      IB: 0.004081,
      JB: 0.004227,
      KB: 0.004418,
      LB: 0.004161,
      MB: 0.004227,
      NB: 0.004725,
      OB: 0.012243,
      PB: 0.008942,
      QB: 0.004707,
      RB: 0.004827,
      SB: 0.004707,
      TB: 0.004707,
      UB: 0.004326,
      VB: 0.008922,
      WB: 0.014349,
      XB: 0.004425,
      YB: 0.00472,
      ZB: 0.004425,
      aB: 0.004425,
      bB: 0.00472,
      cB: 0.004532,
      dB: 0.004566,
      eB: 0.02283,
      fB: 0.00867,
      gB: 0.004656,
      hB: 0.004642,
      iB: 0.003929,
      jB: 0.00944,
      kB: 0.004293,
      lB: 0.003929,
      mB: 0.004298,
      h: 0.096692,
      nB: 0.008162,
      oB: 0.004141,
      pB: 0.004257,
      qB: 0.003939,
      rB: 0.008236,
      P: 0.003855,
      Q: 0.003939,
      R: 0.008514,
      yB: 0.003939,
      S: 0.003939,
      T: 0.003702,
      U: 0.004081,
      V: 0.003855,
      W: 0.003855,
      X: 0.003929,
      Y: 0.003861,
      Z: 0.011703,
      a: 0.007546,
      b: 0.011829,
      c: 0.069498,
      d: 0.020405,
      e: 0.514206,
      SC: 0.00685,
      TC: 0,
      UC: 0.008392,
      VC: 0.004706,
      sB: 0.006229,
      DC: 0.004879,
      WC: 0.008786,
      tB: 0.00472
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "F", "SC", "TC", "UC", "VC", "B", "sB", "DC", "WC", "C", "tB", "G", "M", "N", "O", "y", "g", "z", "0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "AB", "BB", "CB", "DB", "EB", "FB", "GB", "HB", "IB", "JB", "KB", "LB", "MB", "NB", "OB", "PB", "QB", "RB", "SB", "TB", "UB", "VB", "WB", "XB", "YB", "ZB", "aB", "bB", "cB", "dB", "eB", "fB", "gB", "hB", "iB", "jB", "kB", "lB", "mB", "h", "nB", "oB", "pB", "qB", "rB", "P", "Q", "R", "yB", "S", "T", "U", "V", "W", "X", "Y", "Z", "a", "b", "c", "d", "e", "", "", ""],
    E: "Opera",
    F: {
      "0": 1401753600,
      "1": 1405987200,
      "2": 1409616000,
      "3": 1413331200,
      "4": 1417132800,
      "5": 1422316800,
      "6": 1425945600,
      "7": 1430179200,
      "8": 1433808000,
      "9": 1438646400,
      F: 1150761600,
      SC: 1223424000,
      TC: 1251763200,
      UC: 1267488000,
      VC: 1277942400,
      B: 1292457600,
      sB: 1302566400,
      DC: 1309219200,
      WC: 1323129600,
      C: 1323129600,
      tB: 1352073600,
      G: 1372723200,
      M: 1377561600,
      N: 1381104000,
      O: 1386288000,
      y: 1390867200,
      g: 1393891200,
      z: 1399334400,
      AB: 1442448000,
      BB: 1445904000,
      CB: 1449100800,
      DB: 1454371200,
      EB: 1457308800,
      FB: 1462320000,
      GB: 1465344000,
      HB: 1470096000,
      IB: 1474329600,
      JB: 1477267200,
      KB: 1481587200,
      LB: 1486425600,
      MB: 1490054400,
      NB: 1494374400,
      OB: 1498003200,
      PB: 1502236800,
      QB: 1506470400,
      RB: 1510099200,
      SB: 1515024000,
      TB: 1517961600,
      UB: 1521676800,
      VB: 1525910400,
      WB: 1530144000,
      XB: 1534982400,
      YB: 1537833600,
      ZB: 1543363200,
      aB: 1548201600,
      bB: 1554768000,
      cB: 1561593600,
      dB: 1566259200,
      eB: 1570406400,
      fB: 1573689600,
      gB: 1578441600,
      hB: 1583971200,
      iB: 1587513600,
      jB: 1592956800,
      kB: 1595894400,
      lB: 1600128000,
      mB: 1603238400,
      h: 1613520000,
      nB: 1612224000,
      oB: 1616544000,
      pB: 1619568000,
      qB: 1623715200,
      rB: 1627948800,
      P: 1631577600,
      Q: 1633392000,
      R: 1635984000,
      yB: 1638403200,
      S: 1642550400,
      T: 1644969600,
      U: 1647993600,
      V: 1650412800,
      W: 1652745600,
      X: 1654646400,
      Y: 1657152000,
      Z: 1660780800,
      a: 1663113600,
      b: 1668816000,
      c: 1668643200,
      d: 1671062400,
      e: 1675209600
    },
    D: {
      F: "o",
      B: "o",
      C: "o",
      SC: "o",
      TC: "o",
      UC: "o",
      VC: "o",
      sB: "o",
      DC: "o",
      WC: "o",
      tB: "o"
    }
  },
  G: {
    A: {
      E: 0,
      "1B": 0,
      XC: 0,
      EC: 0.00316399,
      YC: 0.00474599,
      ZC: 0.00316399,
      aC: 0.014238,
      bC: 0.00790998,
      cC: 0.018984,
      dC: 0.0569519,
      eC: 0.00316399,
      fC: 0.0696078,
      gC: 0.0205659,
      hC: 0.0237299,
      iC: 0.0205659,
      jC: 0.376515,
      kC: 0.012656,
      lC: 0.0348039,
      mC: 0.0316399,
      nC: 0.0996657,
      oC: 0.259447,
      pC: 0.480927,
      qC: 0.14238,
      "4B": 0.177184,
      "5B": 0.207241,
      "6B": 0.357531,
      "7B": 1.01406,
      uB: 1.11214,
      "8B": 2.47741,
      "9B": 1.67533,
      AC: 6.04164,
      BC: 0.11865,
      CC: 0
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "1B", "XC", "EC", "YC", "ZC", "aC", "E", "bC", "cC", "dC", "eC", "fC", "gC", "hC", "iC", "jC", "kC", "lC", "mC", "nC", "oC", "pC", "qC", "4B", "5B", "6B", "7B", "uB", "8B", "9B", "AC", "BC", "CC", "", ""],
    E: "Safari on iOS",
    F: {
      "1B": 1270252800,
      XC: 1283904000,
      EC: 1299628800,
      YC: 1331078400,
      ZC: 1359331200,
      aC: 1394409600,
      E: 1410912000,
      bC: 1413763200,
      cC: 1442361600,
      dC: 1458518400,
      eC: 1473724800,
      fC: 1490572800,
      gC: 1505779200,
      hC: 1522281600,
      iC: 1537142400,
      jC: 1553472000,
      kC: 1568851200,
      lC: 1572220800,
      mC: 1580169600,
      nC: 1585008000,
      oC: 1600214400,
      pC: 1619395200,
      qC: 1632096000,
      "4B": 1639353600,
      "5B": 1647216000,
      "6B": 1652659200,
      "7B": 1658275200,
      uB: 1662940800,
      "8B": 1666569600,
      "9B": 1670889600,
      AC: 1674432000,
      BC: 1679875200,
      CC: null
    }
  },
  H: {
    A: {
      rC: 1.0085
    },
    B: "o",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "rC", "", "", ""],
    E: "Opera Mini",
    F: {
      rC: 1426464000
    }
  },
  I: {
    A: {
      vB: 0,
      I: 0.0240008,
      H: 0,
      sC: 0,
      tC: 0.0720023,
      uC: 0,
      vC: 0.0160005,
      EC: 0.0720023,
      wC: 0,
      xC: 0.248008
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "sC", "tC", "uC", "vB", "I", "vC", "EC", "wC", "xC", "H", "", "", ""],
    E: "Android Browser",
    F: {
      sC: 1256515200,
      tC: 1274313600,
      uC: 1291593600,
      vB: 1298332800,
      I: 1318896000,
      vC: 1341792000,
      EC: 1374624000,
      wC: 1386547200,
      xC: 1401667200,
      H: 1680652800
    }
  },
  J: {
    A: {
      D: 0,
      A: 0
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "D", "A", "", "", ""],
    E: "Blackberry Browser",
    F: {
      D: 1325376000,
      A: 1359504000
    }
  },
  K: {
    A: {
      A: 0,
      B: 0,
      C: 0,
      h: 0.0111391,
      sB: 0,
      DC: 0,
      tB: 0
    },
    B: "o",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "A", "B", "sB", "DC", "C", "tB", "h", "", "", ""],
    E: "Opera Mobile",
    F: {
      A: 1287100800,
      B: 1300752000,
      sB: 1314835200,
      DC: 1318291200,
      C: 1330300800,
      tB: 1349740800,
      h: 1673827200
    },
    D: {
      h: "webkit"
    }
  },
  L: {
    A: {
      H: 40.8907
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "H", "", "", ""],
    E: "Chrome for Android",
    F: {
      H: 1680652800
    }
  },
  M: {
    A: {
      f: 0.2959
    },
    B: "moz",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "f", "", "", ""],
    E: "Firefox for Android",
    F: {
      f: 1676332800
    }
  },
  N: {
    A: {
      A: 0.0115934,
      B: 0.022664
    },
    B: "ms",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "A", "B", "", "", ""],
    E: "IE Mobile",
    F: {
      A: 1340150400,
      B: 1353456000
    }
  },
  O: {
    A: {
      yC: 0.905454
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "yC", "", "", ""],
    E: "UC Browser for Android",
    F: {
      yC: 1634688000
    },
    D: {
      yC: "webkit"
    }
  },
  P: {
    A: {
      I: 0.147621,
      g: 1.90853,
      zC: 0.0103543,
      "0C": 0.010304,
      "1C": 0.0527219,
      "2C": 0.0103584,
      "3C": 0.0104443,
      "2B": 0.0105043,
      "4C": 0.0210887,
      "5C": 0.0103982,
      "6C": 0.0316331,
      "7C": 0.0316331,
      "8C": 0.0210887,
      uB: 0.0527219,
      "9C": 0.0632662,
      AD: 0.0738106,
      BD: 0.253065
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "I", "zC", "0C", "1C", "2C", "3C", "2B", "4C", "5C", "6C", "7C", "8C", "uB", "9C", "AD", "BD", "g", "", "", ""],
    E: "Samsung Internet",
    F: {
      I: 1461024000,
      zC: 1481846400,
      "0C": 1509408000,
      "1C": 1528329600,
      "2C": 1546128000,
      "3C": 1554163200,
      "2B": 1567900800,
      "4C": 1582588800,
      "5C": 1593475200,
      "6C": 1605657600,
      "7C": 1618531200,
      "8C": 1629072000,
      uB: 1640736000,
      "9C": 1651708800,
      AD: 1659657600,
      BD: 1667260800,
      g: 1677369600
    }
  },
  Q: {
    A: {
      "3B": 0.11836
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "3B", "", "", ""],
    E: "QQ Browser",
    F: {
      "3B": 1663718400
    }
  },
  R: {
    A: {
      CD: 0
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "CD", "", "", ""],
    E: "Baidu Browser",
    F: {
      CD: 1663027200
    }
  },
  S: {
    A: {
      DD: 0.071016,
      ED: 0
    },
    B: "moz",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "DD", "ED", "", "", ""],
    E: "KaiOS Browser",
    F: {
      DD: 1527811200,
      ED: 1631664000
    }
  }
};
export default exports;